<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue';

import SvgIcon from 'JIX/components/SvgIcon.vue';

import i18n from './ShowMore.i18n.js';

import 'icons/expand.svg';

const props = defineProps({
    maxHeight: {
        type: Number,
        required: false,
        default: 200,
    },
    forceReveal: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['isRevealed']);

const content = ref();
const userClickedReveal = ref(false);
const revealing = ref(false);

const fullHeight = ref();
const isUnderMaxHeight = computed(() => {
    return fullHeight.value !== undefined  && fullHeight.value <= props.maxHeight;
});
function measureHeight() {
    fullHeight.value = content.value.getBoundingClientRect().height;
}
onMounted(() => measureHeight());

const isRevealed = computed(() => {
    return props.forceReveal || userClickedReveal.value || fullHeight.value === undefined || isUnderMaxHeight.value;
});
watch(isRevealed, (value) => {
    emit('isRevealed',  { isRevealed: value });
});

function reveal() {
    const collapsedHeight = content.value.getBoundingClientRect().height;
    userClickedReveal.value = true;
    content.value.style.height = `${collapsedHeight}px`;
    revealing.value = true;
    nextTick(() => {
        const _unused = document.body.offsetHeight; // Force page reflow
        content.value.style.height = `${fullHeight.value}px`;

        const el = content.value;
        const cb = el._transitionCb = () => finishAnimation();
        el.addEventListener('transitionend', cb);
    });
}

function finishAnimation() {
    const el = content.value;
    if (el._transitionCb) {
        el.removeEventListener('transitionend', el._transitionCb);
        el._transitionCb = null;
        el.style.height = null;
    }
    revealing.value = false;
}

</script>

<template>
    <div :class="['show-more', { 'show-more--revealing': revealing, 'show-more--obscured': !isRevealed }]">
        <div
            ref="content"
            class="show-more__content"
            :style="{ 'max-height': isRevealed ? null : `${maxHeight}px` }"
        >
            <slot />
        </div>
        <div class="show-more__footer" v-if="!isRevealed">
            <a class="show-more__reveal" href="#" @click.prevent="reveal">
                {{ i18n.showMore() }} <SvgIcon name="expand" class="ml-1" />
            </a>
        </div>
    </div>
</template>
